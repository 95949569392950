import { Injectable } from '@angular/core';
import { GqlService } from '@core';
import { updateTheme } from '@core/gql/domo-dashboard.gql';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StyleControlService {
  private _dataMode = 'light';
  private _dataTheme = 'blue';
  public dataMode$: BehaviorSubject<String> = new BehaviorSubject(
    this._dataMode,
  );
  public get dataMode() {
    return this._dataMode;
  }
  public set dataMode(value) {
    this._dataMode = value;
    this.dataMode$.next(value);
    this.setDataMode();
  }
  public get dataTheme() {
    return this._dataTheme;
  }
  public set dataTheme(value) {
    this._dataTheme = value;
    this.setDataTheme();
  }

  constructor(private readonly gqlService: GqlService) {
    this.setDataMode();
    this.setDataTheme();
  }

  // set data mode
  private setDataMode() {
    const bodyEle = document.getElementsByTagName('body')[0];
    if (bodyEle) {
      bodyEle.dataset.mode = this._dataMode;
    }
  }

  // set data theme
  private setDataTheme() {
    const bodyEle = document.getElementsByTagName('body')[0];
    if (bodyEle) {
      bodyEle.dataset.theme = this._dataTheme;
    }
  }

  updateTheme(theme: string) {
    return this.gqlService.mutate(updateTheme, {
      theme,
    });
  }
}
